<template>
  <div class="card p-4">
    <div class="card-header">
      <div class="d-flex justify-content-end w-100">
        <b-button
          variant="success"
          class="b-button-custom"
          @click="cadastrarNovidade"
        >
          {{ this.novidade_id ? "Editar" : "Cadastrar" }} Novidade</b-button
        >
      </div>
    </div>
    <div class="card-body">
      <Toast position="top-right" />
      <div class="row">
        <div class="col-4">
          <p class="text-title m-0 mb-2">Título</p>
          <b-form-input
            type="text"
            v-model="nova_novidade.titulo"
          ></b-form-input>
        </div>
        <div class="col-8">
          <p class="text-title m-0 mb-2">Descrição Curta</p>
          <b-form-input
            type="text"
            v-model="nova_novidade.descricao"
          ></b-form-input>
        </div>
      </div>
      <div class="row mt-3" v-if="!novidade_id">
        <div class="col-6">
          <p class="text-title m-0 mb-2">Contas</p>
          <MultiSelect
            class="col w-100"
            v-model="nova_novidade.contas"
            :options="contas"
            optionLabel="text"
            placeholder="Contas que receberão a novidade"
            :filter="true"
            size="lg"
          />
        </div>
        <div class="col-6">
          <p class="text-title m-0 mb-2">Usuários</p>
          <MultiSelect
            class="col w-100"
            v-model="nova_novidade.usuarios"
            :options="usuarios"
            optionLabel="text"
            placeholder="Usuários que receberão a novidade"
            :filter="true"
            size="lg"
          />
        </div>
      </div>
      <hr class="mt-3 mb-3" />
      <div class="row">
        <div class="col-12">
          <div
            class="d-flex justify-content-between align-items-center mt-2 mb-2"
          >
            <p class="text-title m-0">Rich Text</p>
            <a class="btn btn-primary" @click="ver_html = !ver_html">
              {{ ver_html ? "Editar Rich Text" : "Editar HTML" }}
            </a>
          </div>
          <ckeditor
            :editor="editor"
            v-model="nova_novidade.html"
            :config="editor_config"
            v-if="!ver_html"
            class="ckeditor"
          ></ckeditor>
          <textarea
            v-model="nova_novidade.html"
            v-if="ver_html"
            class="form-control"
            rows="10"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import API_LINKS from "../../painel/automacao/api.links.js";
import "moment/locale/pt-br";
import Toast from "primevue/toast";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MultiSelect from "primevue/multiselect";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UploadAdapter from "../automacao/campanha/upload_adapter";

export default {
  components: {
    Toast,
    MultiSelect
  },
  data() {
    return {
      nova_novidade: {
        titulo: "",
        descricao: "",
        contas: [],
        usuarios: [],
        html: ""
      },
      novidade_selecionada: {},
      contas: [],
      usuarios: [],
      novidade_id: null,
      editor: ClassicEditor,
      editor_config: {
        extraPlugins: [this.uploader]
      },
      ver_html: false
    };
  },

  methods: {
    uploader(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new UploadAdapter(loader);
      };
    },
    async cadastrarNovidade() {
      if (this.novidade_id) {
        this.editarNovidade();
        return;
      }

      let campos_pendentes = false;
      if (!this.nova_novidade.titulo) {
        campos_pendentes = true;
      } else if (!this.nova_novidade.descricao) {
        campos_pendentes = true;
      } else if (
        !this.novidade_id &&
        !this.nova_novidade.contas.length &&
        !this.nova_novidade.usuarios.length
      ) {
        campos_pendentes = true;
      } else if (!this.nova_novidade.html) {
        campos_pendentes = true;
      }

      if (campos_pendentes) {
        Swal.fire({
          title: "Campos pendentes",
          text: "Preencha todos os campos para cadastrar uma novidade",
          icon: "warning",
          confirmButtonText: "Ok"
        });
        return;
      }

      const payload = {
        novidade: {
          titulo: this.nova_novidade.titulo,
          descricao: this.nova_novidade.descricao,
          contas: this.nova_novidade.contas.map((conta) => conta.value),
          usuarios: this.nova_novidade.usuarios.map((usuario) => usuario.value),
          html: this.nova_novidade.html
        }
      };

      await ApiService.post(API_LINKS.novidades, payload);

      Swal.fire({
        title: "Sucesso",
        text: "Novidade cadastrada com sucesso",
        icon: "success",
        confirmButtonText: "Ok"
      });
      this.$router.push({
        name: "novidade"
      });
    },
    async editarNovidade() {
      let campos_pendentes = false;
      if (!this.nova_novidade.titulo) {
        campos_pendentes = true;
      } else if (!this.nova_novidade.descricao) {
        campos_pendentes = true;
      } else if (!this.nova_novidade.html) {
        campos_pendentes = true;
      }

      if (campos_pendentes) {
        Swal.fire({
          title: "Campos pendentes",
          text: "Preencha todos os campos para cadastrar uma novidade",
          icon: "warning",
          confirmButtonText: "Ok"
        });
        return;
      }

      const payload = {
        novidade: {
          novidade_id: this.novidade_id,
          nov_titulo: this.nova_novidade.titulo,
          nov_descricao: this.nova_novidade.descricao,
          nov_html: this.nova_novidade.html
        }
      };

      await ApiService.put(API_LINKS.novidades, payload);

      Swal.fire({
        title: "Sucesso",
        text: "Novidade editada com sucesso",
        icon: "success",
        confirmButtonText: "Ok"
      });
      this.$router.push({
        name: "novidade"
      });
    },
    pegarContas() {
      ApiService.get(API_LINKS.listarContas).then((res) => {
        this.contas = res.data.data.contas.map((conta) => {
          return {
            ...conta,
            value: conta.conta_id,
            text: conta.con_login
          };
        });
        const usuarios_visitados_count = {};
        this.usuarios = res.data.data.usuarios.map((usuario) => {
          if (usuarios_visitados_count[usuario.usu_login]) {
            usuarios_visitados_count[usuario.usu_login] += 1;
            usuario.usu_login =
              usuario.usu_login +
              " " +
              usuarios_visitados_count[usuario.usu_login];
          } else {
            usuarios_visitados_count[usuario.usu_login] = 1;
          }
          return {
            ...usuario,
            value: usuario.usuario_id,
            text: usuario.usu_login
          };
        });
      });
    }
  },
  mounted() {
    if (this.$route.params.novidade) {
      this.novidade_id = this.$route.params.novidade.novidade_id;
      this.nova_novidade.titulo = this.$route.params.novidade.nov_titulo;
      this.nova_novidade.descricao = this.$route.params.novidade.nov_descricao;
      this.nova_novidade.html = this.$route.params.novidade.nov_html;
    } else {
      this.pegarContas();
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Novidades",
        route: "/painel/novidade"
      },
      {
        title: this.$route.params.novidade ? "Editar" : "Cadastrar"
      }
    ]);
  }
};
</script>

<style scoped>
.modal-deletar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.camp-center {
  display: flex;
  width: 50%;
  height: 100%;
}

.botao-opcoes {
  border: none;
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

.p-multiselect-trigger-icon.pi.pi-chevron-down::before {
  content: none;
}

table#tabelaNovidades .flip-list-move {
  transition: transform 0.5s;
}
</style>
